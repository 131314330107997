<template>
  <div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('province') }">
      <div class="left--col">
        <label for="province-id-field">
          <span>{{ $t('general.province') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <multiselect
          id="province-id-field"
          name="province"
          v-model="province"
          :options="provinces"
          track-by="id"
          label="name"
          :searchable="true"
          :close-on-select="true"
          :class="validation.hasError('province') ? 'is-invalid' : ''"
          :show-labels="true"
          :placeholder="$t('addListing.placeholder.province')"
          select-label=""
          deselect-label=""
        >
        </multiselect>
        <span class="val-error" v-if="validation.hasError('province')">{{
          validation.firstError('province')
        }}</span>
      </div>
    </div>
    <listing-form-transition>
      <div
        v-show="province"
        class="form--group"
        :class="{ 'has-error': validation.hasError('city') }"
      >
        <div class="left--col">
          <label for="city-id-field">
            <span>{{ $t('general.city') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <multiselect
            id="city-id-field"
            name="city"
            v-model="city"
            :options="cities"
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :class="validation.hasError('city') ? 'is-invalid' : ''"
            :show-labels="true"
            :placeholder="$t('addListing.placeholder.city')"
            select-label=""
            deselect-label=""
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('city')">{{
            validation.firstError('city')
          }}</span>
        </div>
      </div>
    </listing-form-transition>
    <listing-form-transition>
      <div v-show="city" class="form--group" :class="{ 'has-error': validation.hasError('area') }">
        <div class="left--col">
          <label for="area-id-field">
            <span>{{ $t('general.area') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <multiselect
            name="area"
            id="area-id-field"
            v-model="area"
            :options="areas"
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :class="validation.hasError('area') ? 'is-invalid' : ''"
            :placeholder="$t('addListing.placeholder.area')"
            select-label=""
            deselect-label=""
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('area')">{{
            validation.firstError('area')
          }}</span>
        </div>
      </div>
    </listing-form-transition>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import { Validator } from 'simple-vue-validator';
import { mapState } from 'vuex';
import ListingFormTransition from '@/components/listing-form/listing-form-transition';

export default {
  name: 'regional-section',
  components: { Multiselect, ListingFormTransition },
  computed: {
    ...mapState({
      provinces: (state) => state.listingForm.sect1.location.regional.provinces,
      cities: (state) => state.listingForm.sect1.location.regional.cities,
      areas: (state) => state.listingForm.sect1.location.regional.areas,
    }),
    province: {
      get() {
        return this.$store.state.listingForm.sect1.location.regional.province;
      },
      set(value) {
        this.$store.dispatch('listingForm/sect1/location/regional/changeProvince', value);
        this.$store.dispatch('listingForm/sect1/location/regional/getCities', value);
      },
    },
    city: {
      get() {
        return this.$store.state.listingForm.sect1.location.regional.city;
      },
      set(value) {
        this.$store.dispatch('listingForm/sect1/location/regional/changeCity', value);
        this.$store.dispatch('listingForm/sect1/location/regional/getAreas', value);
      },
    },
    area: {
      get() {
        return this.$store.state.listingForm.sect1.location.regional.area;
      },
      set(value) {
        this.$store.dispatch('listingForm/sect1/location/regional/changeArea', value);
      },
    },
  },
  methods: {
    validate: function () {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
  validators: {
    province(value) {
      return Validator.value(value).required(this.$i18n.t('errors.province.required'));
    },
    city(value) {
      return Validator.value(value).required(this.$i18n.t('errors.city.required'));
    },
    area(value) {
      return Validator.value(value).required(this.$i18n.t('errors.area.required'));
    },
  },
};
</script>

<style scoped></style>
